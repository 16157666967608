import { useEffect, useState } from "react";
import API from "../../api/API";

export const useProductTypes = () => {
  const [productTypes, setProductTypes] = useState([]);

  useEffect(() => {
    loadProductTypes();
  }, []);

  const loadProductTypes = async () => {
    const productTypes = await API.getProductTypes();

    //Map product types into the required dropdown format
    const formatedProductTypes = productTypes.map((product) => ({
      id: product.productId,
      label: product.description,
    }));

    setProductTypes(formatedProductTypes);
  };

  return productTypes;
};
