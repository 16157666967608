import React from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import { useUIContext } from "../lib/context/UIContext/UIContext";
import PropTypes from "prop-types";

const styles = ({ size, transitions }) => ({
  root: {
    width: `calc(100% - ${size.sidebar.width}px)`,
    marginLeft: `${size.sidebar.width}px`,
    padding: { left: size.spacing, right: size.spacing + 20 },
    transition: transitions.common,
  },

  "@media (max-width:768px)": {
    root: {
      width: "100%",
      padding: { left: size.spacing - 20, right: size.spacing - 20 },
      marginLeft: "0px",
    },
  },

  sidebarClosed: {
    width: "100%",
    marginLeft: "0",
  },
});
const Container = (props) => {
  const { sidebarOpen } = useUIContext();
  const { classes, children } = props;
  return (
    <div
      className={cs(
        classes.root,
        sidebarOpen === false && classes.sidebarClosed
      )}
    >
      {children}
    </div>
  );
};

export default injectSheet(styles)(Container);

Container.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node,
};
