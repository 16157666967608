import React from "react";
import { useTranslation } from "react-i18next";
import injectSheet from "react-jss";
import Chip from "../Chip/Chip";
const ChipsList = (props) => {
  const { classes, data } = props;
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <span>{t("filters")}: </span>
      {data.map((item, index) => (
        <Chip key={`${item.key}-${item.value}-${index}`} data={item} />
      ))}
    </div>
  );
};

const styles = ({ palette, size }) => ({
  root: {
    backgroundColor: palette.activeItem,
    padding: `10px ${size.spacing}px`,
    display: "flex",
    alignItems: "center",
    minHeight: "50px",

    "& > div": {
      marginLeft: "10px",
    },
  },
});
export default injectSheet(styles)(ChipsList);
