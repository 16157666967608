import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AuthContext } from "./AuthContext";
// import jwtDecode from "jwt-decode";
import { setAuthorizationHeader } from "../../../components/utils/utils";
import { tokenHasExpired } from "../../helpers/tokenHasExpired";

const LOCAL_STORAGE_AUTH_KEY = "authToken";
const LOCAL_STORAGE_SUB_KEY = "subscribed";

export const AuthContextProvider = (props) => {
  const [loadingUser, setLoadingUser] = useState(true);
  const [user, setUser] = useState({ subscribed: null, token: null });

  useEffect(() => {
    const token = localStorage.getItem(LOCAL_STORAGE_AUTH_KEY);
    const subscribed = localStorage.getItem(LOCAL_STORAGE_SUB_KEY);
    if (token) {
      if (tokenHasExpired(token)) {
        setUser({ subscribed: null, token: null });
        setLoadingUser(false);
      } else {
        setUser((prev) => ({
          ...prev,
          subscribed: JSON.parse(subscribed),
          token: token,
        }));
        setLoadingUser(false);
        setAuthorizationHeader(token);
      }
    }
    setLoadingUser(false);
  }, []);

  const login = (user) => {
    setUser((prev) => ({
      ...prev,
      subscribed: user.subscribeToShellOffers,
      token: user.token,
    }));
    setLoadingUser(false);
    setAuthorizationHeader(user.token);
    localStorage.setItem(LOCAL_STORAGE_AUTH_KEY, user.token);
    localStorage.setItem(LOCAL_STORAGE_SUB_KEY, user.subscribeToShellOffers);
  };

  const logout = () => {
    setUser({ subscribed: null, token: null });
    setLoadingUser(false);
    setAuthorizationHeader();
    localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
    localStorage.removeItem(LOCAL_STORAGE_SUB_KEY);
  };

  const toggleSubscribed = () => {
    const subscribed = localStorage.getItem(LOCAL_STORAGE_SUB_KEY);
    setUser((prevState) => ({
      ...prevState,
      subscribed: !prevState.subscribed,
    }));
    localStorage.setItem(LOCAL_STORAGE_SUB_KEY, !JSON.parse(subscribed));
  };

  return (
    <AuthContext.Provider
      value={{
        user,
        loadingUser,
        isAuthenticated: !!user.token,
        login,
        logout,
        toggleSubscribed,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = () => useContext(AuthContext);

AuthContextProvider.propTypes = {
  children: PropTypes.node,
};
