import { useFormik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";

export const useChangePasswordFormik = (props) => {
  const { t } = useTranslation();
  const changePasswordSchema = Yup.object().shape({
    currentPassword: Yup.string().required(t("currentPassword")),
    newPassword: Yup.string().required(t("newPassword")),
    confirmNewPassword: Yup.string().required(t("confirmNewPassword")),
  });
  return useFormik({
    initialValues: {
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
    },
    validationSchema: changePasswordSchema,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values, formikHelpers) =>
      await props.onSubmit(values, formikHelpers),
  });
};
