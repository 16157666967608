/**
 * Returns array of years from current year down to the number of years given in param
 *
 * @param {number} years - Number of years to be returned
 * @return {array} - Returns array
 */
export const getYears = (years) => {
  const currentYear = new Date().getFullYear();
  const yearsArray = Array.from({ length: years - 1 }).map((year, idx) => ({
    id: currentYear - (idx + 1),
    label: currentYear - (idx + 1),
  }));
  return [{ id: currentYear, label: currentYear }, ...yearsArray];
};
