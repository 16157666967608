import React from "react";
import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext";
import { PublicRoute } from "./PublicRoute";
import { PrivateRoute } from "./PrivateRoute";
import MyCards from "../pages/MyCards/MyCards";
import Transactions from "../pages/Transactions/Transactions";
import MyProfile from "../pages/MyProfile/MyProfile";
import { ChangePassword } from "../pages/MyProfile/ChangePassword";
import Home from "../pages/Home/Home";
import PageNotFound from "../pages/PageNotFound";
import { Route, Switch } from "react-router";

const RouteType = {
  PUBLIC: "PUBLIC",
  PRIVATE: "PRIVATE",
  RESTRICTED: "RESTRICTED",
};
export const routes = [
  {
    type: RouteType.PRIVATE,
    path: "/",
    component: Home,
    exact: true,
  },
  {
    type: RouteType.PRIVATE,
    path: "/my-cards",
    component: MyCards,
  },
  {
    type: RouteType.PRIVATE,
    path: "/transactions",
    exact: true,
    component: Transactions,
  },
  {
    type: RouteType.PRIVATE,
    path: "/transactions/:id",
    component: Transactions,
  },
  {
    type: RouteType.PRIVATE,
    path: "/my-profile",
    exact: true,
    component: MyProfile,
  },
  {
    type: RouteType.PRIVATE,
    path: "/my-profile/change-password",
    component: ChangePassword,
  },
];

export const Routes = () => {
  const { getLngPrefix } = useLanguageContext();
  const lngPrefix = getLngPrefix();
  return (
    <Switch>
      {routes.map((route) => {
        const { path, type, ...rest } = route;

        if (type === RouteType.PRIVATE)
          return <PrivateRoute {...rest} path={lngPrefix + path} key={path} />;

        if (type === RouteType.RESTRICTED)
          return (
            <PublicRoute
              {...rest}
              path={lngPrefix + path}
              restricted={true}
              key={path}
            />
          );

        return <PublicRoute {...rest} path={lngPrefix + path} key={path} />;
      })}
      <Route component={PageNotFound} />
    </Switch>
  );
};
