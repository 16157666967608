import React from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import { Icon } from "../Icon/Icon";
import PropTypes from "prop-types";

const RoundButton = (props) => {
  const {
    classes,
    type,
    label,
    disabled,
    className,
    loading,
    icon,
    iconClassName,
    ...rest
  } = props;

  const buttonTypes = {
    [classes.danger]: type === "danger",
    [classes.warning]: type === "warning",
    [classes.success]: type === "success",
  };

  return (
    <button
      disabled={disabled || loading}
      className={cs(
        classes.root,
        disabled || loading ? classes.disabled : buttonTypes,
        className
      )}
      type={type === "submit" ? type : undefined}
      {...rest}
    >
      {!loading && icon && (
        <Icon icon={icon} className={cs(classes.icon, iconClassName)} />
      )}
      {loading ? <Icon icon="button-spinner" /> : label}
    </button>
  );
};

const styles = ({ palette }) => ({
  root: {
    background: palette.borderColor,
    color: palette.secondaryTextColor,
    height: "40px",
    padding: "0 14px",
    cursor: "pointer",
    fontSize: "15px",
    borderRadius: "100px",
    margin: "0 5px",
    display: "flex",
    alignItems: "center",
    flexWrap: "nowrap",
    "&:hover": {
      background: palette.button.normalHover,
    },
  },
  icon: {
    marginRight: "7px",
  },

  disabled: {
    background: palette.button.disabled,
    pointerEvents: "none",
    "&:hover": {
      background: palette.button.disabled,
    },
  },
  danger: {
    background: palette.button.danger,
    color: palette.common.white,
    "&:hover": {
      background: palette.button.dangerHover,
    },
  },
  warning: {
    background: palette.button.warning,
    color: palette.common.white,
    "&:hover": {
      background: palette.button.warningHover,
    },
  },
  success: {
    color: palette.common.white,
    background: palette.button.success,
    "&:hover": {
      background: palette.button.successHover,
    },
  },
});

export default injectSheet(styles)(RoundButton);

RoundButton.defaultProps = {
  label: "",
};

RoundButton.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  rest: PropTypes.object,
};
