import React, { useState } from "react";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import { format } from "date-fns";
import Input from "../shared/Input/Input";
import DatePicker from "../DatePicker/DatePicker";
import RectButton from "../shared/RectButton/RectButton";
import DropDown from "../shared/DropDown/DropDown";
import { useProductTypes } from "../../lib/hooks/useProductTypes";
import { Icon } from "../shared/Icon/Icon";

const Filters = (props) => {
  const { classes, status, onFiltersChange } = props;
  const { t } = useTranslation();
  const productTypes = useProductTypes();

  const [plate, setPlate] = useState("");
  const [product, setProduct] = useState({ id: "", label: "" });
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  //Date formatter
  const formatDate = (date) => (date ? format(date, "MM-dd-yyyy") : null);

  const handleClick = () => {
    onFiltersChange({
      plate: plate === "" ? null : plate,
      productId: product.id === "" ? null : product.id,
      startDate: formatDate(startDate),
      endDate: formatDate(endDate),
      page: 1,
    });
  };

  const resetFilters = (e) => {
    e.stopPropagation();
    setPlate("");
    setProduct({ id: "", label: "" });
    setStartDate(undefined);
    setEndDate(undefined);

    onFiltersChange({
      plate: null,
      productId: null,
      startDate: null,
      endDate: null,
      page: 1,
    });
  };

  const handleDateChange = (id, date) => {
    id === "startDate" ? setStartDate(date) : setEndDate(date);
  };

  return (
    <div
      className={cs(
        classes.root,
        status === "open" ? classes.show : classes.hide
      )}
    >
      <Input
        label={t("writeVehicle")}
        id="vehiclePlate"
        labelClassName={classes.filterInput}
        value={plate}
        onChange={(e) => setPlate(e.target.value)}
      />
      <DropDown
        label={t("productType")}
        labelClassName={classes.filterInput}
        options={productTypes}
        selectedOption={product}
        onSelect={(option) =>
          setProduct({ id: option.id, label: option.label })
        }
        onInputChange={(setFilter) => setFilter("")}
      />
      <DatePicker
        id="startDate"
        label={t("dateFrom")}
        date={startDate}
        onDateChange={handleDateChange}
        maximumDate={endDate}
      />
      <DatePicker
        id="endDate"
        label={t("dateTo")}
        date={endDate}
        onDateChange={handleDateChange}
        minimumDate={startDate}
      />

      <div className={classes.resetFilters} onClick={resetFilters}>
        <Icon icon="reset" />
        <span>Reset filters</span>
      </div>

      <RectButton
        label={t("applyFilters")}
        type="warning"
        onClick={handleClick}
      />
    </div>
  );
};
const styles = ({ palette, boxShadow, transitions }) => ({
  root: {
    position: "absolute",
    top: "90px",
    width: "340px",
    padding: "10px 15px",
    backgroundColor: palette.common.white,
    boxShadow: boxShadow.common,
    transition: transitions.faster,
    transformOrigin: "70% top",

    "@media(max-width:768px)": {
      left: 0,
      width: "100%",
    },
    "& > *": {
      marginBottom: "30px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  show: { transform: "scale(1)" },
  hide: { transform: "scale(0)" },
  filterInput: {
    fontSize: "1rem",
  },
  resetFilters: {
    display: "flex",
    alignItems: "center",
    cursor: "pointer",
    "& span": {
      marginLeft: "5px",
    },
    "&:hover": {
      color: palette.secondaryTextColor,
    },
  },
});
export default injectSheet(styles)(Filters);
