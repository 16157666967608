import React from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import PropTypes from "prop-types";

const Input = (props) => {
  const {
    id,
    type,
    classes,
    value,
    onChange,
    error,
    helperText,
    multiLine,
    label,
    className,
    labelClassName,
    disabled,
    ...rest
  } = props;

  return (
    <div className={classes.field}>
      <div className={cs(classes.fieldControl, disabled && classes.disabled)}>
        {multiLine ? (
          <textarea
            id={id && id}
            className={cs(classes.fieldInput, error && classes.fieldInputError)}
            placeholder=" "
            value={value}
            onChange={onChange}
            {...rest}
            disabled={disabled}
          />
        ) : (
          <input
            id={id && id}
            type={type || "text"}
            className={cs(
              classes.fieldInput,
              error && classes.fieldInputError,
              className
            )}
            placeholder=" "
            value={value}
            onChange={onChange}
            {...rest}
            disabled={disabled}
          />
        )}
        <label
          className={cs(classes.fieldLabel, labelClassName)}
          htmlFor={id && id}
        >
          {label}
        </label>
      </div>
      {(helperText || error) && (
        <label className={cs(classes.helperText, error && classes.fieldError)}>
          {helperText}
        </label>
      )}
    </div>
  );
};

const styles = ({ palette, transitions, disabled }) => ({
  field: {
    display: "block",
  },
  fieldControl: {
    background: palette.activeItem,
    borderRadius: "8px 8px 0 0",
    overflow: "hidden",
    position: "relative",
  },
  fieldInput: {
    appearance: "none",
    background: "transparent",
    border: 0,
    color: palette.leadTextColor,
    display: "block",
    fontSize: "1.2rem",
    paddingTop: "24px",
    padding: "0 12px 10px 12px",
    width: "100%",
    boxShadow: "inset 0 -1px 0 #7B8C92",
    transition: transitions.common,
    resize: "vertical",
    "&:focus": {
      boxShadow: "inset 0 -2px 0 #43BCF2",
    },
    "&:focus + $fieldLabel , &:not(:placeholder-shown) + $fieldLabel": {
      fontSize: "0.75rem",
      color: "#43BCF2",
      transform: "translateY(-14px)",
    },
  },
  fieldInputError: {
    boxShadow: `inset 0 -2px 0 ${palette.error}`,
  },

  fieldLabel: {
    fontSize: "1.2rem",
    position: "absolute",
    top: 0,
    left: 0,
    margin: 0,
    width: "100%",
    padding: "18px 12px 0",
    color: palette.leadTextColor,
    transition: transitions.common,
  },

  helperText: {
    fontSize: "0.9rem",
    marginLeft: "12px",
    width: "100%",
    marginTop: "5px",
    color: palette.leadTextColor,
    display: "inline-block",
    whiteSpace: "nowrap",
  },

  fieldError: {
    color: palette.error,
  },
  disabled,
});

export default injectSheet(styles)(Input);

Input.propTypes = {
  id: PropTypes.string,
  type: PropTypes.string,
  classes: PropTypes.object,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  error: PropTypes.bool,
  helperText: PropTypes.string,
  multiLine: PropTypes.bool,
  label: PropTypes.string,
  className: PropTypes.object,
  rest: PropTypes.node,
};
