import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import injectSheet from "react-jss";
import Button from "../Button/Button";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import PropTypes from "prop-types";

const ConfirmModal = (props) => {
  const modalRef = useRef(null);
  const { t } = useTranslation();

  const { classes, setIsOpen } = props;
  const { isOpen, onConfirm, onCancel, label } = props.data;

  const handleClickOutside = () => {
    setIsOpen(false);
  };
  useOutsideClickDetection(modalRef, handleClickOutside);
  return (
    <>
      {isOpen && (
        <div className={classes.root}>
          <div className={classes.modalContent} ref={modalRef}>
            <p className={classes.title}>{t("confirm")}</p>
            <p className={classes.content}>{label || t("confirmContent")}</p>
            <div className={classes.buttons}>
              <Button
                className={classes.button}
                type="danger"
                label={t("yes")}
                onClick={onConfirm}
              />
              <Button
                className={classes.button}
                type="warning"
                label={t("no")}
                onClick={onCancel}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

const styles = ({ palette, boxShadow, typography, size }) => ({
  root: {
    position: "fixed",
    zIndex: "100",
    width: "100vw",
    left: 0,
    top: 0,
    height: "100vh",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: boxShadow.common,
    padding: `15px ${size.spacing / 2}px`,
    width: "500px",
    "@media (max-width:768px)": {
      width: "calc(100vw - 50px)",
      maxWidth: "500px",
      textAlign: "center",
      padding: `15px ${size.spacing}px`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  title: {
    fontWeight: typography.weight.medium,
    fontSize: typography.headingFontSize,
    color: palette.leadTextColor,
    margin: 0,
  },
  content: {
    color: palette.secondaryTextColor,
    fontSize: typography.defaultFontSize,
  },
  buttons: {
    "@media (max-width:768px)": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxWidth: "300px",
      "& :first-child": {
        marginBottom: "10px",
      },
    },
  },
  button: {
    padding: "0 30px",
  },
  messageWrapper: {
    textAlign: "center",
    padding: "10px",
    "& p": {
      marginTop: 0,
    },
  },
  okButton: {
    padding: "0 30px",
  },
});

export default injectSheet(styles)(ConfirmModal);

ConfirmModal.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.shape({
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
  }),
};
