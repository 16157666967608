import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useLanguageContext } from "../lib/context/LanguageContext/LanguageContext";
import { useAuthContext } from "../lib/context/AuthContext/AuthContextProvider";
import PropTypes from "prop-types";

export const PrivateRoute = (props) => {
  const { component: Component, ...rest } = props;
  const { isAuthenticated } = useAuthContext();
  const { getLngPrefix } = useLanguageContext();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect to={`${getLngPrefix()}/login`} />
        )
      }
    />
  );
};

PrivateRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  rest: PropTypes.object,
};
