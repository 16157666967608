import React from "react";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import PageLayout from "../../layouts/PageLayout";
import RectButton from "../../components/shared/RectButton/RectButton";
import { Icon } from "../../components/shared/Icon/Icon";
import { Link } from "react-router-dom";
import API from "../../api/API";
import { useAuthContext } from "../../lib/context/AuthContext/AuthContextProvider";

function MyProfile(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const { user, toggleSubscribed, logout } = useAuthContext();

  const handleCheckboxChange = async () => {
    try {
      await API.user.toggleSubscription();
      toggleSubscribed();
    } catch (e) {}
  };

  return (
    <PageLayout title={`${t("hello")},`}>
      <p>{t("personalData")}</p>
      <div className={classes.companyInfo}>
        <div>
          <p className={classes.heading}>{t("personalInfos")}</p>
          <p>
            <span className={classes.key}>{t("name")}:</span>
            <span className={classes.value}>Gentrit Humolli</span>
          </p>
          <p>
            <span className={classes.key}> Email:</span>
            <span className={classes.value}>gentrithumolli@kutia.net</span>
          </p>
          <p>
            <input
              type="checkbox"
              className={classes.checkbox}
              id="checkbox"
              checked={user.subscribed}
              onChange={handleCheckboxChange}
            />
            <label className={classes.label} htmlFor="checkbox">
              {t("subscribe")}
            </label>
          </p>
        </div>
        <div>
          <div className={classes.changePassword}>
            <Icon icon="lock" className={classes.icon} />
            <Link to="my-profile/change-password" className={classes.link}>
              {t("changePassword")}
            </Link>
          </div>
          <RectButton
            label={t("logout")}
            type="danger"
            icon="profile"
            onClick={logout}
          />
        </div>
      </div>
    </PageLayout>
  );
}
const styles = ({ palette, typography }) => ({
  companyInfo: {
    color: palette.secondaryTextColor,
    width: "100%",
    padding: "40px 20px",
    backgroundColor: palette.activeItem,
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-between",
    alignItems: "center",
  },

  heading: {
    fontWeight: typography.weight.medium,
    fontSize: "1.2rem",
    margin: 0,
  },

  icon: {
    width: "14px",
    height: "14px",
    marginRight: "5px",
  },

  changePassword: {
    display: "flex",
    marginBottom: "10px",
  },

  checkbox: {
    margin: 0,
    width: "15px",
    height: "15px",
    marginRight: "5px",
    cursor: "pointer",
  },

  key: {
    fontWeight: typography.weight.normal,
    marginRight: "5px",
  },

  value: {
    fontWeight: typography.weight.light,
  },

  link: {
    color: palette.common.black,
    fontWeight: typography.weight.light,
    "&:hover": {
      color: palette.button.danger,
    },
  },

  label: {
    fontWeight: typography.weight.light,
  },
});
export default injectSheet(styles)(MyProfile);
