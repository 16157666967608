import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../Icon/Icon";
import PropTypes from "prop-types";

const HandleError = (props) => {
  const { error, children, classes } = props;
  const errArray = error?.split(" ");
  const errorType = errArray[errArray?.length - 1];

  if (errorType === "401") return null;

  return error ? (
    <div className={classes.error}>
      <Icon icon="error" className={classes.icon} />
      <p>{error}</p>
    </div>
  ) : (
    children
  );
};

const styles = ({ palette, typography }) => ({
  error: {
    color: palette.error,
    textAlign: "center",
    fontWeight: typography.weight.normal,
  },
  icon: {
    width: "100px",
    height: "100px",
    margin: "auto",
  },
});

export default injectSheet(styles)(HandleError);

HandleError.propTypes = {
  classes: PropTypes.object,
  children: PropTypes.node.isRequired,
  error: PropTypes.string,
};
