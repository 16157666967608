import React from "react";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import Table from "../../shared/Table/Table";
import NoTransactionsPlaceholder from "./NoTransactionsPlaceholder";
function TransactionsTable(props) {
  const { data, tableActions, filterParams, loading, error } = props;
  const { t } = useTranslation();
  const columns = [
    {
      key: "transaction_date",
      header: t("date"),
    },
    {
      key: "product_per_l",
      header: t("productPerL"),
    },
    {
      key: "plate",
      header: t("plage"),
    },
    {
      key: "transaction_total",
      header: t("total"),
    },
  ];

  return (
    <Table
      title={t("transactions")}
      columns={columns}
      rows={data}
      tableActions={tableActions}
      loading={loading}
      error={error}
      emptyDataPlaceholder={<NoTransactionsPlaceholder />}
      appliedFilters={filterParams}
    />
  );
}
const styles = {
  transactionActions: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "25px",
    height: "21px",
    "& g": {
      fill: "#434D71",
    },
  },
};
export default injectSheet(styles)(TransactionsTable);
