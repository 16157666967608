import PropTypes from "prop-types";

const HandleLoading = (props) => {
  const { loading, loadingPlaceholder, children } = props;
  return loading ? loadingPlaceholder : children;
};

export default HandleLoading;

HandleLoading.propTypes = {
  children: PropTypes.node.isRequired,
  loading: PropTypes.bool.isRequired,
  loadingPlaceholder: PropTypes.object.isRequired,
};
