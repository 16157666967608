import React, { useEffect, useState } from "react";
import injectSheet from "react-jss";
import { Icon } from "../Icon/Icon";
import { orderBy } from "lodash";
import cs from "classnames";
import HandleLoading from "../HandleLoading/HandleLoading";
import HandleError from "../HandleError/HandleError";
import TableLoadingSkeleton from "./Table.skeleton";
import PropTypes from "prop-types";
import ChipsList from "../ChipsList/ChipsList";

const Table = (props) => {
  const { classes, onRowClick } = props;
  const {
    title,
    loading,
    error,
    tableActions,
    emptyDataPlaceholder,
    appliedFilters,
  } = props;

  const [columns, setColumns] = useState(props.columns);
  const [rows, setRows] = useState(props.rows);

  const [sortKey, setSortKey] = useState("");
  const [sortType, setSortType] = useState("desc");

  useEffect(() => {
    setRows(props.rows);
    setColumns(props.columns);
  }, [props.rows, props.columns, tableActions]);

  //Will sort table cols asc or desc
  const sortByCol = (colKey) => {
    const type = sortType === "asc" ? "desc" : "asc";
    setSortKey(colKey);
    setSortType(type);
    const sortedRows = orderBy(
      rows,
      (e) => {
        const row = e[colKey];
        if (typeof row === "object") {
          return row.props && row.props["data-sort"]
            ? row.props["data-sort"]
            : null;
        }
        return e[colKey];
      },
      [type]
    );
    setRows(sortedRows);
  };

  return (
    <div className={classes.root}>
      <div className={classes.header}>
        <div className={classes.title}>{title}</div>
        {tableActions && tableActions}
      </div>
      {appliedFilters && <ChipsList data={appliedFilters} />}
      <div className={classes.tableWrapper}>
        <HandleLoading
          loading={loading}
          loadingPlaceholder={<TableLoadingSkeleton />}
        >
          <HandleError error={error}>
            <table className={classes.table}>
              <thead>
                <tr>
                  {columns.map((col, idx) => (
                    <th key={idx} style={{ width: col.customWidth }}>
                      {col.header}
                      {col.sortable && (
                        <button
                          className={cs(
                            classes.filter,
                            sortKey === col.key &&
                              sortType === "asc" &&
                              classes.filterAsc
                          )}
                          onClick={() => sortByCol(col.key)}
                        >
                          <Icon icon="sort" />
                        </button>
                      )}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {rows.length === 0 ? (
                  <tr className={classes.emptyPlaceholder}>
                    <td colSpan={columns.length + 1}>{emptyDataPlaceholder}</td>
                  </tr>
                ) : (
                  rows.map((row, index) => (
                    <tr
                      key={index}
                      onClick={
                        onRowClick
                          ? () => onRowClick(row.cardNumber)
                          : undefined
                      }
                      className={onRowClick ? classes.clickable : undefined}
                    >
                      {columns.map((rowColumn, idx) => (
                        <td key={idx}>{row[rowColumn["key"]]}</td>
                      ))}
                    </tr>
                  ))
                )}
              </tbody>
            </table>
          </HandleError>
        </HandleLoading>
      </div>
    </div>
  );
};

const styles = ({ size, typography, palette, transitions, boxShadow }) => ({
  root: {
    borderRadius: "8px 8px 0 0",
    background: palette.common.white,
    color: palette.leadTextColor,
    boxShadow: boxShadow.common,
    position: "relative",
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",

    padding: {
      top: size.spacing - 10,
      left: size.spacing,
      right: size.spacing,
      bottom: size.spacing / 2,
    },
    "@media(max-width:768px)": {
      alignItems: "space-between",
      justifyContent: "center",
      paddingRight: size.spacing / 2,
      height: "200px",
    },
  },
  filter: {
    background: "none",
    color: palette.leadTextColor,
    verticalAlign: "inherit",
    transition: transitions.common,
    cursor: "pointer",
  },

  title: {
    display: "flex",
    alignItems: "center",
    fontWeight: typography.weight.bold,
    fontSize: typography.headingFontSize,
    margin: "auto 0",
    marginRight: "5px",
  },
  tableWrapper: {
    overflow: "auto",
  },
  table: {
    width: "100%",
    textAlign: "left",
    borderCollapse: "collapse",
    fontSize: "1rem",
    "@media (max-width:768px)": {
      minWidth: "1000px",
    },

    "& td,th": {
      padding: {
        top: "10px",
        bottom: "10px",
        left: size.spacing,
        right: size.spacing,
      },
    },
    "& th": {
      fontWeight: typography.weight.medium,
      paddingTop: "15px",
      whiteSpace: "nowrap",
    },

    "& td": {
      fontWeight: typography.weight.normal,
    },
    "& td:last-child": {
      textAlign: "center",
    },
    "& th:last-child": {
      textAlign: "center",
    },
    "& tbody > tr:nth-child(odd)": {
      background: palette.activeItem,
      borderTop: "1px solid #EFEFEF",
      borderBottom: "1px solid #EFEFEF",
    },
  },

  filterDesc: {
    transform: "rotateZ(180) rotateY(180)",
  },
  filterAsc: {
    transition: transitions.common,
    transform: "rotateZ(180deg) rotateY(180deg)",
  },
  clickable: {
    cursor: "pointer",
    "&:hover": {
      background: palette.tableRowHover + " !important",
    },
  },
  emptyPlaceholder: {
    background: "white !important",
    border: "0 !important",
  },
});

export default injectSheet(styles)(Table);

Table.defaultProps = {
  columns: [],
  rows: [],
};

Table.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  loading: PropTypes.bool,
  error: PropTypes.string,
  button: PropTypes.element,
};
