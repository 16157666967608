import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../shared/Icon/Icon";
import { enGB } from "date-fns/locale";
import { DatePicker as DateP } from "react-nice-dates";
import "react-nice-dates/build/style.css";

const DatePicker = (props) => {
  const {
    classes,
    label,
    date,
    onDateChange,
    id,
    minimumDate,
    maximumDate,
  } = props;

  return (
    <div className={classes.wrapper}>
      <label className={classes.label}>{label}</label>
      <DateP
        date={date}
        format="MM-dd-yyyy"
        onDateChange={(date) => onDateChange(id, date)}
        locale={enGB}
        minimumDate={minimumDate}
        maximumDate={maximumDate}
      >
        {({ inputProps }) => (
          <input id={id} className={classes.root} {...inputProps} />
        )}
      </DateP>
      <label htmlFor={id}>
        <Icon icon="calendar" className={classes.icon} />
      </label>
    </div>
  );
};
const styles = ({ palette }) => ({
  root: {
    position: "relative",
    marginTop: "10px",
    border: "1px solid black",
    width: "100%",
    height: "50px",
    borderRadius: "50px",
    fontSize: "1rem",
    color: palette.leadTextColor,
    paddingLeft: "20px",
  },

  wrapper: {
    position: "relative",
  },
  label: {
    marginLeft: "10px",
  },
  icon: {
    position: "absolute",
    cursor: "pointer",
    top: 39,
    right: 25,
  },
});
export default injectSheet(styles)(DatePicker);
