import React, { useRef, useState } from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import PropTypes from "prop-types";
import { Icon } from "../../shared/Icon/Icon";

const SmallerDropdown = (props) => {
  const ddRef = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const {
    classes,
    className,
    label,
    options,
    selectedOption,
    onSelect,
    disabled,
  } = props;
  useOutsideClickDetection(ddRef, handleClickOutside);

  function handleClickOutside() {
    setIsOpen(false);
  }

  const toggleDd = () => setIsOpen((prevState) => !prevState);

  const handleItemClick = (item) => {
    onSelect(item.id);
  };

  return (
    <div
      className={cs(classes.root, className, disabled && classes.disabled)}
      onClick={toggleDd}
      ref={ddRef}
    >
      <p className={classes.label}>{selectedOption?.label || label}</p>
      <Icon
        icon="down-arrow"
        className={cs(classes.arrow, isOpen && classes.rotateArrow)}
      />

      <div className={classes.listContainer}>
        {isOpen && (
          <ul className={classes.list}>
            {options?.map((option) => (
              <li
                className={cs(
                  classes.listItem,
                  selectedOption?.id === option.id
                    ? classes.activeItem
                    : undefined
                )}
                key={option.id}
                onClick={() => handleItemClick(option)}
              >
                {option.label}
              </li>
            ))}
          </ul>
        )}
      </div>
    </div>
  );
};

const styles = ({ palette, boxShadow, transitions, disabled }) => ({
  root: {
    width: "100%",
    maxWidth: "300px",
    minWidth: "90px",
    position: "relative",
    backgroundColor: palette.activeItem,
    height: "40px",
    display: "flex",
    alignItems: "center",
    borderRadius: "10px",
    transition: transitions.common,
    cursor: "pointer",
    userSelect: "none",
  },
  listContainer: {
    marginTop: "10px",
    borderRadius: "10px",
    position: "absolute",
    zIndex: "2",
    width: "100%",
    maxWidth: "300px",
    backgroundColor: palette.common.white,
    boxShadow: boxShadow.common,
    maxHeight: "300px",
    overflow: "auto",
    left: 0,
    top: 40,
  },

  listItem: {
    listStyle: "none",
    padding: "10px 15px",
    fontSize: "0.9rem",
    "&:hover ": {
      background: palette.activeItem,
    },
  },

  list: { margin: 0, padding: 0 },

  activeItem: { background: palette.activeItem },

  label: {
    margin: 0,
    marginLeft: "15px",
    fontSize: "0.9rem",
  },
  arrow: {
    width: "13px",
    height: "13px",
    position: "absolute",
    top: 14,
    right: 10,
    transition: transitions.common,
  },

  rotateArrow: {
    transform: "scaleY(-1)",
  },
  disabled,
});

export default injectSheet(styles)(SmallerDropdown);

SmallerDropdown.propTypes = {
  classes: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.object),
  selectedOption: PropTypes.object,
  onSelect: PropTypes.func,
  mBottom: PropTypes.bool,
};
