import React from "react";
import { useTranslation } from "react-i18next";
import injectSheet from "react-jss";
import { Icon } from "../components/shared/Icon/Icon";
import PageLayout from "../layouts/PageLayout";

const PageNotFound = (props) => {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <PageLayout>
      <div className={classes.root}>
        <Icon icon="error" className={classes.icon} />
        <h1 className={classes.error}>404</h1>
        <p className={classes.message}>{t("pageNotFound")}</p>
      </div>
    </PageLayout>
  );
};
const styles = ({ palette }) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "250px",
  },
  icon: {
    width: "120px",
    height: "120px",
    fill: palette.button.danger,
  },
  error: {
    color: palette.button.danger,
    fontSize: "3rem",
    margin: "0",
  },
  message: {
    margin: 0,
  },
});
export default injectSheet(styles)(PageNotFound);
