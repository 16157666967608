import { useTranslation } from "react-i18next";

const useMonths = () => {
  const { t } = useTranslation();
  return [
    { id: 1, label: t("January") },
    { id: 2, label: t("February") },
    { id: 3, label: t("March") },
    { id: 4, label: t("April") },
    { id: 5, label: t("May") },
    { id: 6, label: t("June") },
    { id: 7, label: t("July") },
    { id: 8, label: t("August") },
    { id: 9, label: t("September") },
    { id: 10, label: t("October") },
    { id: 11, label: t("November") },
    { id: 12, label: t("December") },
  ];
};

export default useMonths;
