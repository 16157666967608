import React, { useCallback, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import injectSheet from "react-jss";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import { Icon } from "../../shared/Icon/Icon";
import RectButton from "../../shared/RectButton/RectButton";
import ReqNewCardModal from "../../ReqNewCardModal/ReqNewCardModal";
import Table from "../../shared/Table/Table";
import API from "../../../api/API";
import { useConfirm } from "../../../lib/context/ConfirmContext/ConfirmContext";
import { useRouter } from "../../../lib/hooks/useRouter";
import { useLanguageContext } from "../../../lib/context/LanguageContext/LanguageContext";
import Input from "../../shared/Input/Input";
import RoundButton from "../../shared/RoundButton/RoundButton";
import NoCardsPlaceholder from "./NoCardsPlaceholder";
import MessageModal from "../../shared/MessageModal/MessageModal";

function MyCardsTable(props) {
  const { classes } = props;
  const { t } = useTranslation();
  const { confirm } = useConfirm();
  const { getLngPrefix } = useLanguageContext();
  const router = useRouter();

  const [modalStatus, setModalStatus] = useState("closed");
  const [messageModalStatus, setMessageModalStatus] = useState("closed");
  const [messageModalText, setMessageModalText] = useState("");

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const [myCards, setMyCards] = useState([]);
  const [deleting, setDeleting] = useState("");
  const [filter, setFilter] = useState("");

  const [params, setParams] = useState({
    page: 1,
    hasMore: false,
  });

  const modalRef = useRef(null);
  useOutsideClickDetection(modalRef, () => setModalStatus("closed"));

  const loadMyCards = useCallback(async () => {
    try {
      setLoading(true);
      const data = await API.cards.getAll(params.page);
      setMyCards((prev) => [...prev, ...data.cards]);
      setParams((prev) => ({ ...prev, hasMore: data.hasMore }));
      setLoading(false);
    } catch (e) {
      setError(e.toString());
      setLoading(false);
    }
  }, [params.page]);

  useEffect(() => {
    loadMyCards();
  }, [loadMyCards]);

  const loadMore = () => {
    if (loading) return;
    setParams((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const toggleModal = () =>
    setModalStatus((prevStatus) => (prevStatus === "open" ? "closed" : "open"));

  const handleDelete = async (e, cardNumber) => {
    e.stopPropagation();
    try {
      await confirm();
      setDeleting(cardNumber);
      const response = await API.cards.deactivateCard(cardNumber);
      setDeleting("");
      setMessageModalText(response.message);
      setMessageModalStatus("open");
    } catch (e) {
      if (e !== undefined) {
        setMessageModalText(e.response.data.message);
        setMessageModalStatus("open");
      }
    } finally {
      setDeleting("");
    }
  };

  const handleRowClick = (id) => {
    router.history.push(`${getLngPrefix()}/transactions/${id}`);
  };

  const columns = [
    {
      key: "number",
      header: "Nr.",
      customWidth: "10px",
    },
    // {
    //   key: "person",
    //   header: "Person",
    // },
    {
      key: "registration_plate",
      header: t("plage"),
    },
    {
      key: "card_number",
      header: t("cardNumber"),
    },
    {
      key: "actions",
      header: t("actions"),
      customWidth: "50px",
    },
  ];

  const filteredMyCards = (filter) => {
    if (!filter) return myCards;

    return myCards.filter(
      (card) => card.plates.includes(filter) || card.cardNumber.includes(filter)
    );
  };

  //Pagination
  // const CARDS_PER_PAGE = 50;
  // const indexOfLastPost = page * CARDS_PER_PAGE;

  const rows = filteredMyCards(filter).map((card, idx) => ({
    number: (
      <span className={!card.isActivated ? classes.disabled : undefined}>
        {idx + 1}
      </span>
    ),
    // person: <span>{card.person}</span>,
    registration_plate: (
      <span className={!card.isActivated ? classes.disabled : undefined}>
        {card.plates}
      </span>
    ),
    card_number: (
      <span className={!card.isActivated ? classes.disabled : undefined}>
        {card.cardNumber}
      </span>
    ),
    actions: card.isActivated && (
      <div
        className={classes.deleteIcon}
        onClick={(e) => handleDelete(e, card.cardNumber)}
      >
        {deleting === card.cardNumber ? (
          <Icon icon="button-spinner" />
        ) : (
          <Icon icon="delete" />
        )}
      </div>
    ),
    cardNumber: card.cardNumber,
  }));

  return (
    <>
      <Table
        title={t("myCards")}
        columns={columns}
        rows={rows}
        loading={loading && params.page === 1}
        error={error}
        onRowClick={handleRowClick}
        emptyDataPlaceholder={<NoCardsPlaceholder />}
        tableActions={
          <>
            <Input
              label={t("filter")}
              id="filter"
              value={filter}
              onChange={(e) => setFilter(e.target.value)}
            />
            <span ref={modalRef} className={classes.button}>
              <RectButton
                label={t("requestNewCard")}
                type="warning"
                onClick={toggleModal}
              />
              <ReqNewCardModal
                status={modalStatus}
                onStatusChange={setModalStatus}
              />
            </span>
          </>
        }
      />
      {params.hasMore && (
        <RoundButton
          loading={loading}
          className={classes.loadMoreButton}
          label={t("loadMore")}
          onClick={loadMore}
        />
      )}
      <MessageModal
        message={messageModalText}
        status={messageModalStatus}
        onStatusChange={setMessageModalStatus}
      />
    </>
  );
}
const styles = {
  deleteIcon: {
    display: "flex",
    justifyContent: "center",
    color: "#DD1D21",
    cursor: "pointer",
    "&:hover": {
      color: "#e61e21",
    },
  },
  loadMoreButton: {
    margin: "15px auto",
  },
  button: {
    width: "300px",
  },
  disabled: {
    color: "#DEDEDE",
  },
};
export default injectSheet(styles)(MyCardsTable);
