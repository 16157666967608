import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../Icon/Icon";
const Chip = (props) => {
  const { classes, data } = props;

  return (
    <div className={classes.root}>
      <span>
        {data.label}: {data.value}
      </span>
      {data.onRemove && (
        <span onClick={data.onRemove} className={classes.circle}>
          <Icon icon="clear" className={classes.icon} />
        </span>
      )}
    </div>
  );
};

const styles = ({ palette }) => ({
  root: {
    backgroundColor: palette.borderColor,
    color: palette.secondaryTextColor,
    fontSize: "0.9rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "5px 10px",
    maxHeight: "30px",
    borderRadius: "100px",
  },
  circle: {
    marginLeft: "5px",
    borderRadius: "100px",
    backgroundColor: palette.leadColor,
    padding: "1px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: palette.activeItem,
    },
  },
  icon: {
    width: "15px",
    height: "15px",
  },
});
export default injectSheet(styles)(Chip);
