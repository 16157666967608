import React from "react";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import Input from "../../shared/Input/Input";
import RectButton from "../../shared/RectButton/RectButton";

const ChangePasswordForm = (props) => {
  const { t } = useTranslation();
  const { classes, formik, response } = props;
  return (
    <form
      autoComplete="off"
      className={classes.form}
      onSubmit={formik.handleSubmit}
    >
      <Input
        label={t("currentPassword")}
        id="currentPassword"
        type="password"
        value={formik.values.currentPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          !!formik.errors.currentPassword && formik.touched.currentPassword
        }
        helperText={
          formik.errors.currentPassword &&
          formik.touched.currentPassword &&
          formik.errors.currentPassword
        }
      />
      <Input
        label={t("newPassword")}
        id="newPassword"
        type="password"
        value={formik.values.newPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={!!formik.errors.newPassword && formik.touched.newPassword}
        helperText={
          formik.errors.newPassword &&
          formik.touched.newPassword &&
          formik.errors.newPassword
        }
      />
      <Input
        label={t("confirmNewPassword")}
        id="confirmNewPassword"
        type="password"
        value={formik.values.confirmNewPassword}
        onChange={formik.handleChange}
        onBlur={formik.handleBlur}
        error={
          !!formik.errors.confirmNewPassword &&
          formik.touched.confirmNewPassword
        }
        helperText={
          formik.errors.confirmNewPassword &&
          formik.touched.confirmNewPassword &&
          formik.errors.confirmNewPassword
        }
      />
      {response && (
        <p
          className={
            response.type === "success" ? classes.success : classes.error
          }
        >
          {response.message}
        </p>
      )}
      <RectButton
        label={t("changePassword")}
        type="warning"
        loading={formik.isSubmitting}
      />
    </form>
  );
};

const styles = ({ palette }) => ({
  form: {
    "& > *": {
      marginBottom: "30px",
      width: "400px",
      "@media(max-width:768px)": {
        width: "100%",
      },
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  error: {
    color: palette.button.danger,
  },
  success: {
    color: palette.button.success,
  },
});
export default injectSheet(styles)(ChangePasswordForm);
