//
// WARNING
//
// Do not make manual changes to this file.
// This file was generated by generate-AllBusinessIcons-component.js.
//
//


import React from 'react';

export const IconSvg = {
  'button-spinner': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50"><path d="M43.935 25.145c0-10.318-8.364-18.683-18.683-18.683-10.318 0-18.683 8.365-18.683 18.683h4.068c0-8.071 6.543-14.615 14.615-14.615s14.615 6.543 14.615 14.615h4.068z"><animateTransform attributeName="transform" attributeType="xml" dur="0.6s" from="0 25 25" repeatCount="indefinite" to="360 25 25" type="rotate"/></path></svg>,
  'calendar': <svg xmlns="http://www.w3.org/2000/svg" data-name="Component 9 – 1" viewBox="0 0 24 24"><path fill="#404040" d="M20.906 1.875H18.75V.75a.75.75 0 00-1.5 0v1.125H6.75V.75a.75.75 0 00-1.5 0v1.125H3.094A3.1 3.1 0 000 4.969v15.937A3.1 3.1 0 003.094 24h17.812A3.1 3.1 0 0024 20.906V4.969a3.1 3.1 0 00-3.094-3.094zm-17.812 1.5H5.25v.75a.75.75 0 001.5 0v-.75h10.5v.75a.75.75 0 001.5 0v-.75h2.156A1.6 1.6 0 0122.5 4.969V6.75h-21V4.969a1.6 1.6 0 011.594-1.594zM20.906 22.5H3.094A1.6 1.6 0 011.5 20.906V8.25h21v12.656a1.6 1.6 0 01-1.594 1.594z" data-name="Path 1731"/></svg>,
  'card': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M20 2H4c-1.11 0-2 .89-2 2v11c0 1.11.89 2 2 2h4v5l4-2 4 2v-5h4c1.11 0 2-.89 2-2V4c0-1.11-.89-2-2-2zm0 13H4v-2h16v2zm0-5H4V4h16v6z"/></svg>,
  'clear': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M19 6.41L17.59 5 12 10.59 6.41 5 5 6.41 10.59 12 5 17.59 6.41 19 12 13.41 17.59 19 19 17.59 13.41 12z"/></svg>,
  'delete': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M6 19c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V7H6v12zM19 4h-3.5l-1-1h-5l-1 1H5v2h14V4z"/></svg>,
  'down-arrow': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 256 256"><path d="M225.813 48.907L128 146.72 30.187 48.907 0 79.093l128 128 128-128z"/></svg>,
  'error': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm1 15h-2v-2h2v2zm0-4h-2V7h2v6z"/></svg>,
  'excel': <svg xmlns="http://www.w3.org/2000/svg" data-name="Component 8 – 1" viewBox="0 0 17.76 16.651"><path fill="#fff" d="M17.205 14.431h-7.77a.555.555 0 01-.555-.555v-11.1a.555.555 0 01.555-.555h7.77a.555.555 0 01.555.555v11.1a.555.555 0 01-.555.555z" data-name="Path 1839"/><g fill="#388e3c" data-name="Group 1728"><path d="M11.655 5.551h-2.22a.555.555 0 110-1.11h2.22a.555.555 0 110 1.11z" data-name="Path 1840"/><path d="M11.655 7.771h-2.22a.555.555 0 110-1.11h2.22a.555.555 0 110 1.11z" data-name="Path 1841"/><path d="M11.655 9.991h-2.22a.555.555 0 110-1.11h2.22a.555.555 0 110 1.11z" data-name="Path 1842"/><path d="M11.655 12.211h-2.22a.555.555 0 110-1.11h2.22a.555.555 0 110 1.11z" data-name="Path 1843"/><path d="M14.985 5.551h-1.11a.555.555 0 010-1.11h1.11a.555.555 0 010 1.11z" data-name="Path 1844"/><path d="M14.985 7.771h-1.11a.555.555 0 010-1.11h1.11a.555.555 0 010 1.11z" data-name="Path 1845"/><path d="M14.985 9.991h-1.11a.555.555 0 010-1.11h1.11a.555.555 0 010 1.11z" data-name="Path 1846"/><path d="M14.985 12.211h-1.11a.555.555 0 110-1.11h1.11a.555.555 0 010 1.11z" data-name="Path 1847"/></g><path fill="#fff" d="M9.789.129a.545.545 0 00-.456-.118L.453 1.676A.553.553 0 000 2.221v12.21a.554.554 0 00.453.545l8.88 1.665a.522.522 0 00.1.01.555.555 0 00.555-.555V.556a.554.554 0 00-.199-.427z" data-name="Path 1848"/><path fill="#008443" d="M7.633 10.181L5.878 8.175l1.775-2.282a.555.555 0 00-.876-.683L5.133 7.323 3.748 5.74a.555.555 0 00-.835.73l1.526 1.745-1.548 1.99a.555.555 0 00.877.682l1.415-1.82 1.614 1.844a.554.554 0 10.835-.729z" data-name="Path 1849"/></svg>,
  'filter': <svg xmlns="http://www.w3.org/2000/svg" data-name="Component 6 – 1" viewBox="0 0 25.727 22.017"><g fill="#404040" data-name="Group 1601"><path d="M9.562 3.59h15.327a.838.838 0 100-1.675H9.561a2.752 2.752 0 00-5.243 0H.837a.838.838 0 100 1.675h3.481a2.752 2.752 0 005.243 0z" data-name="Path 1728"/><path d="M21.288 11.846h3.6a.838.838 0 000-1.675h-3.6a2.752 2.752 0 00-5.243 0H.837a.838.838 0 100 1.675h15.209a2.752 2.752 0 005.243 0z" data-name="Path 1729"/><path d="M11.488 22.013a2.752 2.752 0 002.621-1.915h10.78a.838.838 0 000-1.675H14.108a2.752 2.752 0 00-5.243 0H.837a.838.838 0 000 1.675h8.028a2.753 2.753 0 002.623 1.915z" data-name="Path 1730"/></g></svg>,
  'language': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M11.99 2C6.47 2 2 6.48 2 12s4.47 10 9.99 10C17.52 22 22 17.52 22 12S17.52 2 11.99 2zm6.93 6h-2.95a15.65 15.65 0 00-1.38-3.56A8.03 8.03 0 0118.92 8zM12 4.04c.83 1.2 1.48 2.53 1.91 3.96h-3.82c.43-1.43 1.08-2.76 1.91-3.96zM4.26 14C4.1 13.36 4 12.69 4 12s.1-1.36.26-2h3.38c-.08.66-.14 1.32-.14 2 0 .68.06 1.34.14 2H4.26zm.82 2h2.95c.32 1.25.78 2.45 1.38 3.56A7.987 7.987 0 015.08 16zm2.95-8H5.08a7.987 7.987 0 014.33-3.56A15.65 15.65 0 008.03 8zM12 19.96c-.83-1.2-1.48-2.53-1.91-3.96h3.82c-.43 1.43-1.08 2.76-1.91 3.96zM14.34 14H9.66c-.09-.66-.16-1.32-.16-2 0-.68.07-1.35.16-2h4.68c.09.65.16 1.32.16 2 0 .68-.07 1.34-.16 2zm.25 5.56c.6-1.11 1.06-2.31 1.38-3.56h2.95a8.03 8.03 0 01-4.33 3.56zM16.36 14c.08-.66.14-1.32.14-2 0-.68-.06-1.34-.14-2h3.38c.16.64.26 1.31.26 2s-.1 1.36-.26 2h-3.38z"/></svg>,
  'lock': <svg xmlns="http://www.w3.org/2000/svg" data-name="Component 6 – 1" viewBox="0 0 14 18"><defs><clipPath id="a"><path fill="#db2026" d="M16-14H7a1 1 0 00-1 1v8a1 1 0 001 1h10a1 1 0 001-1v-8a1 1 0 00-1-1zm-8-1v-2a4 4 0 014-4 4.11 4.11 0 014 4v2h1a2 2 0 012 2v8a2 2 0 01-2 2H7a2 2 0 01-2-2v-8a2 2 0 012-2zm1 0h6v-2a3.111 3.111 0 00-3-3 3 3 0 00-3 3zm3 7a1 1 0 01-1-1 1 1 0 011-1 1 1 0 011 1 1 1 0 01-1 1z" data-name="Path 1574"/></clipPath><clipPath id="b"><path fill="#db2026" d="M0 0h24v-24H0z" data-name="Path 1573"/></clipPath></defs><g clip-path="url(#a)" data-name="Group 1384" transform="translate(-5 21)"><g clip-path="url(#b)" data-name="Group 1383"><path fill="#db2026" d="M0 2h24v-28H0z" data-name="Path 1572"/></g></g></svg>,
  'logout': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M11 7L9.6 8.4l2.6 2.6H2v2h10.2l-2.6 2.6L11 17l5-5-5-5zm9 12h-8v2h8c1.1 0 2-.9 2-2V5c0-1.1-.9-2-2-2h-8v2h8v14z"/></svg>,
  'menu_open': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M3 18h13v-2H3v2zm0-5h10v-2H3v2zm0-7v2h13V6H3zm18 9.59L17.42 12 21 8.41 19.59 7l-5 5 5 5L21 15.59z"/></svg>,
  'pdf': <svg xmlns="http://www.w3.org/2000/svg" data-name="Icon simple-adobeacrobatreader" viewBox="0 0 16.573 16.544"><path fill="#fff" d="M16.321 10.619a4.054 4.054 0 00-2.922-.828 14.232 14.232 0 00-2.6.256 14.015 14.015 0 01-1.6-1.881 16.209 16.209 0 01-.978-1.588 14.781 14.781 0 00.834-4.219c0-1.156-.417-2.363-1.618-2.363a1.129 1.129 0 00-.938.558c-.542.974-.3 3.107.634 5.215a46.848 46.848 0 01-1.172 3.185c-.4.952-.86 1.932-1.33 2.833-2.7 1.091-4.447 2.375-4.611 3.377a1.114 1.114 0 00.316 1 1.55 1.55 0 001.024.377c1.791 0 3.679-2.96 4.639-4.745a80.297 80.297 0 012.208-.7c.808-.223 1.618-.4 2.355-.529a6.619 6.619 0 004.4 2.006c1.032 0 1.4-.427 1.524-.776a1.2 1.2 0 00-.174-1.2l.014.028zm-.958.729a.88.88 0 01-.959.627 2.476 2.476 0 01-.417-.05 6.412 6.412 0 01-2.7-1.457 12.256 12.256 0 012.061-.173 5.512 5.512 0 011.252.1c.339.073.878.3.757.954h.014zm-5.2-1.181c-.636.131-1.324.286-2.036.479-.564.154-1.152.328-1.743.533.32-.624.591-1.227.835-1.8.3-.705.539-1.432.785-2.107.242.422.512.851.782 1.231a19.073 19.073 0 001.366 1.675v-.014zM6.943.841a.537.537 0 01.469-.3c.515 0 .613.6.613 1.079a13.43 13.43 0 01-.664 3.435c-.735-1.95-.785-3.583-.417-4.212zm-2.7 11.686c-1.252 2.1-2.454 3.412-3.19 3.412a.6.6 0 01-.367-.127.539.539 0 01-.172-.526c.147-.754 1.546-1.807 3.729-2.76z" data-name="Icon simple-adobeacrobatreader"/></svg>,
  'profile': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M12 5.9a2.1 2.1 0 110 4.2 2.1 2.1 0 010-4.2m0 9c2.97 0 6.1 1.46 6.1 2.1v1.1H5.9V17c0-.64 3.13-2.1 6.1-2.1M12 4C9.79 4 8 5.79 8 8s1.79 4 4 4 4-1.79 4-4-1.79-4-4-4zm0 9c-2.67 0-8 1.34-8 4v3h16v-3c0-2.66-5.33-4-8-4z"/></svg>,
  'reset': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M17.65 6.35A7.958 7.958 0 0012 4c-4.42 0-7.99 3.58-7.99 8s3.57 8 7.99 8c3.73 0 6.84-2.55 7.73-6h-2.08A5.99 5.99 0 0112 18c-3.31 0-6-2.69-6-6s2.69-6 6-6c1.66 0 3.14.69 4.22 1.78L13 11h7V4l-2.35 2.35z"/></svg>,
  'shell-notrans': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 71.6 71.6"><circle cx="35.8" cy="35.8" r="35.8" fill="#003b87"/><path fill="none" d="M0 0h71.6v71.6H0z"/><path fill="#a6a6a6" d="M59.4 20.1h-5c-1.6 0-2.9 1.3-2.9 2.9v5c0 1.6 1.3 2.9 2.9 2.9h5c1.6 0 2.9-1.3 2.9-2.9v-5c0-1.7-1.3-2.9-2.9-2.9z"/><path fill="#f0f0f0" d="M12.2 22.9v39.8c6.5 5.8 14.9 8.9 23.6 8.9 7.5 0 14.7-2.3 20.8-6.6V22.9c0-1.6 1.3-2.9 2.9-2.9H15c-1.5.1-2.7 1.4-2.8 2.9z"/><path fill="#a6a6a6" d="M49.2 38H18.4c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h30.8c.6 0 1.1-.5 1.1-1.1 0-.7-.5-1.1-1.1-1.1zm0 5H18.4c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h30.8c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1zm0 5H18.4c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h30.8c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1zm0 5H18.4c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h30.8c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1zm-8.6-22.9h8.6c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1h-8.6c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1zM39.9 58H18.4c-.6 0-1.1.5-1.1 1.1 0 .6.5 1.1 1.1 1.1h21.5c.6 0 1.1-.5 1.1-1.1 0-.6-.5-1.1-1.1-1.1zM19.8 26.5c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5zm7.2 0c-1.4 0-2.5 1.1-2.5 2.5s1.1 2.5 2.5 2.5 2.5-1.1 2.5-2.5-1.1-2.5-2.5-2.5z"/><path fill="none" d="M-.6 0H71v71.6H-.6z"/></svg>,
  'shell': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100"><path fill="#fff" d="M81.728 93.671l-25.48-.028-1.024.75a9.169 9.169 0 01-5.323 1.65 9.336 9.336 0 01-5.5-1.881l-.763-.531-25.556-.031L15.7 74.646 1.185 63.886l-.165-.768A36.726 36.726 0 010 53.372a50 50 0 11100 .1 36.865 36.865 0 01-1.046 9.743l-.168.774-14.681 10.732z"/><path fill="#db2026" d="M3.057 62.683a34.736 34.736 0 01-.974-9.309 47.918 47.918 0 1195.833.1 34.871 34.871 0 01-1 9.3L82.15 73.569l-2.26 18.014-24.322-.027-1.571 1.15a7.117 7.117 0 01-4.1 1.25 7.284 7.284 0 01-4.314-1.504l-1.3-.9-24.37-.027-2.256-18.022-14.6-10.82z"/><path fill="#fbce07" d="M45.271 82.664l2.065 1.585a5.1 5.1 0 002.729 1.068 5.114 5.114 0 002.752-1.062l2.066-1.581 17.913.021 1.876-14.847 14.245-10.409a16.006 16.006 0 00.664-4.985 21.4 21.4 0 00-1.67-7.985L62.082 70.362l24.835-30.657a19.886 19.886 0 00-5.966-10.581L58.473 67.833l19.679-42.441a20.016 20.016 0 00-10.155-7.4L54.273 66.226l9.919-50.209a20.384 20.384 0 00-10.025-2.6c-.98.006-1.158 0-2.083 0l-2.067 52.359-2.1-52.359h-2.079a20.69 20.69 0 00-10.075 2.65l-.2.117 10.319 50.133-13.655-48.3a19.83 19.83 0 00-10.2 7.294l19.77 42.364-22.134-39.138a20.4 20.4 0 00-6.577 10.837l24.9 31.032-25.792-26.254a20.019 20.019 0 00-1.777 8.318 19.294 19.294 0 00.649 5.047l14.492 10.674 1.783 14.45z"/></svg>,
  'sort': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0z"/><path d="M3 18h6v-2H3v2zM3 6v2h18V6H3zm0 7h12v-2H3v2z"/></svg>,
  'transactions': <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path fill="none" d="M0 0h24v24H0V0z"/><path d="M19.5 3.5L18 2l-1.5 1.5L15 2l-1.5 1.5L12 2l-1.5 1.5L9 2 7.5 3.5 6 2v14H3v3c0 1.66 1.34 3 3 3h12c1.66 0 3-1.34 3-3V2l-1.5 1.5zM19 19c0 .55-.45 1-1 1s-1-.45-1-1v-3H8V5h11v14z"/><path d="M9 7h6v2H9zM16 7h2v2h-2zM9 10h6v2H9zM16 10h2v2h-2z"/></svg>,
};
