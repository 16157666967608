import React from "react";
import injectSheet from "react-jss";
import PropTypes from "prop-types";

const PageLayout = (props) => {
  const { classes, title, children } = props;
  return (
    <div className={classes.root}>
      <p className={classes.title}>{title}</p>
      {children}
    </div>
  );
};

const styles = ({ palette, typography, size, boxShadow }) => ({
  root: {
    borderRadius: "8px",
    background: palette.common.white,
    color: palette.secondaryTextColor,
    boxShadow: boxShadow.common,
    padding: size.spacing,
    marginBottom: size.spacing,
    "@media(max-width:768px)": {
      padding: size.spacing / 1.5,
    },
  },
  title: {
    fontWeight: typography.weight.bold,
    fontSize: typography.headingFontSize,
    margin: 0,
    marginBottom: size.spacing,
  },
});

export default injectSheet(styles)(PageLayout);

PageLayout.propTypes = {
  classes: PropTypes.object,
  title: PropTypes.string,
  children: PropTypes.node,
};
