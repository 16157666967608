import React, { useState } from "react";
import { ConfirmContext } from "./ConfirmContext";
import ConfirmModal from "../../../components/shared/ConfirmModal/ConfirmModal";
import PropTypes from "prop-types";

export const ConfirmContextProvider = (props) => {
  const [state, setState] = useState({
    isOpen: false,
    label: "",
    onConfirm: () => {},
    onCancel: () => {},
  });

  const setIsOpen = (value) => {
    setState((prevState) => ({ ...prevState, isOpen: value }));
  };

  const confirm = (label) => {
    return new Promise((resolve, reject) => {
      setState((prevState) => ({
        ...prevState,
        isOpen: !prevState.isOpen,
        label: label && label,
        onConfirm: () => {
          setState({ ...prevState, isOpen: false });
          resolve();
        },
        onCancel: () => {
          setState({ ...prevState, isOpen: false });
          reject();
        },
      }));
    });
  };

  return (
    <ConfirmContext.Provider value={{ isOpen: state.isOpen, confirm: confirm }}>
      {props.children}
      <ConfirmModal data={state} setIsOpen={setIsOpen} />
    </ConfirmContext.Provider>
  );
};

ConfirmContextProvider.propTypes = {
  children: PropTypes.node,
};
