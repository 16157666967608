import React, { useState } from "react";
import { UIContext } from "./UIContext";
import PropTypes from "prop-types";

export const UIContextProvider = (props) => {
  const { children } = props;
  const [isDesktop, setIsDesktop] = useState(window.innerWidth > 768);
  const [sidebarOpen, setSidebarOpen] = useState(isDesktop);

  return (
    <UIContext.Provider
      value={{ sidebarOpen, setSidebarOpen, isDesktop, setIsDesktop }}
    >
      {children}
    </UIContext.Provider>
  );
};

UIContextProvider.propTypes = {
  children: PropTypes.node,
};
