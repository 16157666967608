import axios from "axios";

export const setAuthorizationHeader = (token) => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    delete axios.defaults.headers.common.Authorization;
  }
};

export const areObjEqual = (obj1, obj2) => {
  if (obj1 === null) return true;
  return Object.keys(obj1).every(
    (key) => obj2.hasOwnProperty(key) && obj2[key] === obj1[key]
  );
};
