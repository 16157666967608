import React from "react";
import injectSheet from "react-jss";

import MyCardsTable from "../../components/tables/MyCardsTable/MyCardsTable";

function MyCards() {
  return <MyCardsTable />;
}
const styles = {};
export default injectSheet(styles)(MyCards);
