import React from "react";
import injectSheet from "react-jss";
import Skeleton from "react-loading-skeleton";

const TableSkeleton = (props) => {
  const { classes } = props;
  return (
    <div>
      <table className={classes.table}>
        <thead>
          <tr>
            <th>
              <Skeleton />
            </th>
            <th>
              <Skeleton width="150px" />
            </th>
            <th>
              <Skeleton width="150px" />
            </th>
            <th>
              <Skeleton />
            </th>
          </tr>
        </thead>
        <tbody>
          {Array.from({ length: 4 }, (v, idx) => (
            <tr key={idx}>
              <td style={{ width: "100px" }}>
                <Skeleton width={40} height="30px" />
              </td>
              <td>
                <Skeleton height="30px" />
              </td>
              <td>
                <Skeleton height="30px" />
              </td>
              <td style={{ width: "100px" }}>
                <Skeleton height="30px" />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

const styles = ({ palette, size, boxShadow, typography }) => ({
  root: {
    borderRadius: "8px 8px 0 0",
    background: palette.common.white,
    color: palette.leadTextColor,
    boxShadow: boxShadow.common,
    paddingBottom: size.spacing / 2,
    marginBottom: size.spacing,
    "@media (max-width:768px),(max-width:1200px)": {
      overflow: "scroll",
    },
  },
  header: {
    display: "flex",
    justifyContent: "space-between",
    padding: {
      top: size.spacing - 10,
      right: size.spacing,
      bottom: size.spacing / 2,
      left: size.spacing,
    },
    "@media(max-width:768px)": { paddingRight: size.spacing / 2 },
  },
  buttons: { width: "150px", marginLeft: "15px" },
  title: {
    fontWeight: typography.weight.medium,
    fontSize: typography.headingFontSize,
    margin: "auto 0",
    padding: "5px 0",
    width: "100px",
  },
  table: {
    width: "100%",
    textAlign: "left",
    borderCollapse: "collapse",
    fontSize: typography.defaultFontSize,

    "@media (max-width:768px)": {
      minWidth: "1200px",
    },

    "& td,th": {
      padding: {
        top: "17px",
        bottom: "10px",
        left: size.spacing,
        right: size.spacing,
      },
    },
    "& th": {
      fontWeight: typography.weight.medium,
    },

    "& td": {
      fontWeight: typography.weight.normal,
    },
  },
});
export default injectSheet(styles)(TableSkeleton);
