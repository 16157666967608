import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import injectSheet from "react-jss";
import Button from "../Button/Button";
import { useOutsideClickDetection } from "../../../lib/hooks/useOutsideClickDetection";
import PropTypes from "prop-types";

const MessageModal = (props) => {
  const { classes, message, status, onStatusChange } = props;

  const modalRef = useRef(null);
  const { t } = useTranslation();
  const handleClick = () => {
    onStatusChange("closed");
  };
  useOutsideClickDetection(modalRef, handleClick);
  return (
    <>
      {status === "open" && (
        <div className={classes.root}>
          <div className={classes.modalContent} ref={modalRef}>
            <p className={classes.content}>{message}</p>
            <Button
              className={classes.button}
              type="success"
              label={t("Ok")}
              onClick={handleClick}
            />
          </div>
        </div>
      )}
    </>
  );
};

const styles = ({ palette, boxShadow, typography, size }) => ({
  root: {
    position: "fixed",
    zIndex: "100",
    width: "100vw",
    left: 0,
    top: 0,
    height: "100vh",
    overflow: "auto",
    backgroundColor: "rgba(0,0,0,0.4)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  modalContent: {
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: boxShadow.common,
    padding: `15px ${size.spacing / 2}px`,
    textAlign: "center",
    width: "500px",
    "& p": {
      marginTop: "10px",
    },
    "@media (max-width:768px)": {
      width: "calc(100vw - 50px)",
      maxWidth: "500px",
      textAlign: "center",
      padding: `15px ${size.spacing}px`,
      display: "flex",
      flexDirection: "column",
      alignItems: "center",
    },
  },
  title: {
    fontWeight: typography.weight.medium,
    fontSize: typography.headingFontSize,
    color: palette.leadTextColor,
    margin: 0,
  },
  content: {
    color: palette.secondaryTextColor,
    fontSize: typography.defaultFontSize,
  },
  buttons: {
    "@media (max-width:768px)": {
      display: "flex",
      flexDirection: "column",
      width: "100%",
      maxWidth: "300px",
      "& :first-child": {
        marginBottom: "10px",
      },
    },
  },
  button: {
    padding: "0 30px",
  },
});

export default injectSheet(styles)(MessageModal);

MessageModal.propTypes = {
  classes: PropTypes.object,
  data: PropTypes.shape({
    isOpen: PropTypes.bool,
    onConfirm: PropTypes.func,
    onCancel: PropTypes.func,
  }),
};
