import React, { useEffect, useState } from "react";
import detector from "i18next-browser-languagedetector";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { LanguageContext } from "./LanguageContext";
import { useRouter } from "../../hooks/useRouter";
import LoadingScreen from "../../../components/shared/LoadingScreen/LoadingScreen";
import { Root } from "../../../App";
import PropTypes from "prop-types";
import API from "../../../api/API";

export const LanguageContextProvider = (props) => {
  const [loading, setLoading] = useState(true);
  const router = useRouter();
  const { children } = props;

  useEffect(() => {
    loadTranslations();
  }, []);

  const loadTranslations = async () => {
    const translations = await API.getLocales();

    i18n
      .use(detector)
      .use(initReactI18next)
      .init({
        resources: {
          sq: { translation: translations.sq },
          en: { translation: translations.en },
        },
        fallbackLng: "sq",
        whitelist: ["en", "sq"],
        detection: {
          order: ["path"],
          checkWhitelist: true,
          lookupFromPathIndex: 0,
        },
        interpolation: {
          escapeValue: false,
          formatSeparator: ".",
        },
      });

    setLoading(false);
  };

  const getLngPrefix = () =>
    !i18n.language || i18n.language === "sq" ? "" : `/${i18n.language}`;

  const removeLangFromURL = (url) => {
    return url.replace("/en", "");
  };

  const changeLanguage = (lang) => {
    const newPrefix = lang === "sq" ? "" : "/en";
    const newPath = removeLangFromURL(router.location.pathname);
    i18n.changeLanguage(lang);
    router.history.push(newPrefix + newPath);
  };

  if (loading) {
    return (
      <Root>
        <LoadingScreen />
      </Root>
    );
  }

  return (
    <LanguageContext.Provider value={{ loading, getLngPrefix, changeLanguage }}>
      {children}
    </LanguageContext.Provider>
  );
};
LanguageContextProvider.propTypes = {
  children: PropTypes.node,
};
