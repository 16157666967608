import React from "react";
import injectSheet from "react-jss";
const InfoCard = (props) => {
  const { classes, children } = props;
  return <div className={classes.root}>{children}</div>;
};

const styles = ({ palette, boxShadow, size }) => ({
  root: {
    backgroundColor: palette.common.white,
    boxShadow: boxShadow.common,
    borderRadius: "8px",
    height: "185px",
    width: "32%",
    padding: "30px 20px",
    "@media(max-width:768px)": {
      width: "100%",
    },
  },
});
export default injectSheet(styles)(InfoCard);
