import React from "react";
import injectSheet from "react-jss";
import { getYears } from "../../../lib/helpers/getYears";
import { useMonth } from "../../../lib/hooks/useMonth";
import useMonths from "../../../lib/hooks/useMonths";
import SmallerDropdown from "./SmallerDropdown";

const MonthYearFilter = (props) => {
  const {
    classes,
    month,
    year,
    onMonthChange,
    onYearChange,
    handleMonthAndYearChange,
  } = props;

  const months = useMonths();
  const monthObject = useMonth(month);
  const years = getYears(6);

  //Get selected year from years array
  const getSelectedYear = (selectedYear) =>
    years.find((year) => year.id === selectedYear);

  const handleMonthChange = (item) => {
    onMonthChange(item);
    handleMonthAndYearChange();
  };

  const handleYearChange = (item) => {
    onYearChange(item);
    handleMonthAndYearChange();
  };

  return (
    <div className={classes.root}>
      <SmallerDropdown
        options={months}
        selectedOption={monthObject}
        onSelect={handleMonthChange}
        label="Select month"
      />

      <SmallerDropdown
        options={years}
        selectedOption={getSelectedYear(year)}
        onSelect={handleYearChange}
        label="Select year"
      />
    </div>
  );
};
const styles = {
  root: {
    display: "flex",
    width: "300px",
    "& div:first-child": {
      marginRight: "10px",
    },
  },
};
export default injectSheet(styles)(MonthYearFilter);
