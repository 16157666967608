import axios from "axios";
import { API_URL } from "../config";
import i18n from "i18next";
const LOCAL_STORAGE_AUTH_KEY = "authToken";

axios.interceptors.response.use(
  (response) => response,
  (error) => {
    const status = error.response.status;
    if (status === 401) {
      localStorage.removeItem(LOCAL_STORAGE_AUTH_KEY);
      window.location.reload();
    }
    return Promise.reject(error);
  }
);

const userConfig = {
  headers: {
    "Content-Type": "application/x-www-form-urlencoded;charset=utf-8",
  },
};

// const formConfig = {
//   headers: { "Content-Type": "multipart/form-data", Accept: "*/*" },
// };

export default {
  user: {
    login: (userCredentials) =>
      axios
        .post(
          `${API_URL + i18n.language}/api/login/b2b`,
          userCredentials,
          userConfig
        )
        .then((response) => response.data.user),

    changePassword: (updatedPassword) =>
      axios.put(
        `${API_URL + i18n.language}/api/b2b/change-password`,
        updatedPassword,
        userConfig
      ),

    me: () =>
      axios
        .get(`${API_URL + i18n.language}/api/me`)
        .then((response) => response.data),

    toggleSubscription: () =>
      axios
        .post(`${API_URL + i18n.language}/api/toggle-subscription`)
        .then((response) => response.data),

    getVisitsPerDay: () =>
      axios
        .get(`${API_URL + i18n.language}/api/b2b/visits-per-day`)
        .then((response) => response.data),

    getExpensesPerMonth: (year) =>
      axios
        .get(`${API_URL + i18n.language}/api/expenses-per-month`, {
          params: { year },
        })
        .then((response) => response.data),
  },
  cards: {
    getAll: (pageNumber) =>
      axios
        .get(`${API_URL + i18n.language}/api/b2b/cards`, {
          params: { pageNumber },
        })
        .then((response) => response.data),

    requestNewCard: (plate) =>
      axios
        .post(
          `${API_URL + i18n.language}/api/b2b/request-new-card?plate=${plate}`
        )
        .then((response) => response.data),
    deactivateCard: (cardNumber) =>
      axios
        .post(`${API_URL + i18n.language}/api/b2b/deactivate-card`, null, {
          params: { cardNumber },
        })
        .then((response) => response.data),
  },
  transactions: {
    get: (params) =>
      axios
        .get(`${API_URL + i18n.language}/api/transactions`, {
          params,
        })
        .then((response) => response.data),

    downloadTransactions: (type, params) =>
      axios
        .get(`${API_URL + i18n.language}/api/download-transactions/${type}`, {
          params,
          responseType: "blob",
        })
        .then((response) => response.data),
  },

  getLocales: () =>
    axios.get(`${API_URL}get_labels`).then((response) => response.data),
  getProductTypes: () =>
    axios
      .get(`${API_URL + i18n.language}/api/derivates-prices`)
      .then((response) => response.data.data),
};
