import React, { useState } from "react";
import PageLayout from "../../layouts/PageLayout";
import { useTranslation } from "react-i18next";
import ChangePasswordForm from "../../components/forms/ChangePasswordForm/ChangePasswordForm";
import { useChangePasswordFormik } from "../../components/forms/ChangePasswordForm/lib/useChangePasswordFormik";
import API from "../../api/API";

export const ChangePassword = () => {
  const { t } = useTranslation();
  const [response, setResponse] = useState({ type: "", message: "" });
  const formik = useChangePasswordFormik({
    onSubmit: async (values, formikHelpers) => {
      if (values.newPassword !== values.confirmNewPassword)
        return formikHelpers.setFieldError(
          "confirmNewPassword",
          t("passwordDontMatch")
        );

      const newPassword = new URLSearchParams();
      newPassword.append("currentPassword", values.currentPassword);
      newPassword.append("newPassword", values.newPassword);
      newPassword.append("confirmPassword", values.confirmNewPassword);

      try {
        const response = await API.user.changePassword(newPassword);
        setResponse({ type: "success", message: response.data.message });
        formikHelpers.resetForm();
      } catch (e) {
        console.log(e.response);
        setResponse({
          type: "error",
          message: "Error while changing password, please try again!", //TODO: Display error message from API response
        });
      }
    },
  });
  return (
    <PageLayout title={t("changePassword")}>
      <ChangePasswordForm formik={formik} response={response} />
    </PageLayout>
  );
};
