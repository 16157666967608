import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../shared/Icon/Icon";
import { NavLink } from "react-router-dom";
import { useUIContext } from "../../lib/context/UIContext/UIContext";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";

const styles = ({ palette, typography }) => ({
  root: {
    minHeight: "64px",
    padding: "0 40px",
    fontSize: typography.headingFontSize,
    display: "flex",
    alignItems: "center ",
    "& span": {
      marginLeft: "20px",
    },
    color: palette.secondaryTextColor,
    "&:hover": {
      background: palette.activeItem,
      cursor: "pointer",
    },
  },
  icon: {
    color: palette.leadTextColor,
  },

  active: {
    background: "#fffcfc",
  },
});

const SideBarItem = (props) => {
  const { classes } = props;
  const { icon, name, path } = props;
  const { isDesktop, setSidebarOpen } = useUIContext();
  const { getLngPrefix } = useLanguageContext();
  return (
    <NavLink
      exact
      activeClassName={classes.active}
      to={getLngPrefix() + path}
      className={classes.root}
      onClick={() => isDesktop === false && setSidebarOpen(false)}
    >
      <Icon icon={icon} className={classes.icon} />
      <span>{name}</span>
    </NavLink>
  );
};

export default injectSheet(styles)(SideBarItem);
