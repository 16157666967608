import React from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import { Icon } from "../Icon/Icon";
import PropTypes from "prop-types";

const Button = (props) => {
  const { classes, type, label, disabled, className, loading, ...rest } = props;

  const buttonTypes = {
    [classes.danger]: type === "danger",
    [classes.warning]: type === "warning",
    [classes.success]: type === "success",
  };

  return (
    <button
      disabled={disabled || loading}
      className={cs(
        classes.root,
        disabled || loading ? classes.disabled : buttonTypes,
        type === "submit" && classes.warning,
        className
      )}
      type={type === "submit" ? type : undefined}
      {...rest}
    >
      {loading ? <Icon icon="button-spinner" /> : label}
    </button>
  );
};

const styles = ({ palette }) => ({
  root: {
    background: palette.button.normal,
    height: "40px",
    padding: "0 14px",
    cursor: "pointer",
    textAlign: "center",
    fontSize: "15px",
    color: "#fff",
    borderRadius: "100px",
    margin: "0 5px",

    "&:hover": {
      background: palette.button.normalHover,
    },
  },

  disabled: {
    background: palette.button.disabled,
    "&:hover": {
      background: palette.button.disabled,
    },
  },
  danger: {
    background: palette.button.danger,
    "&:hover": {
      background: palette.button.dangerHover,
    },
  },
  warning: {
    background: palette.button.warning,
    "&:hover": {
      background: palette.button.warningHover,
    },
  },
  success: {
    background: palette.button.success,
    "&:hover": {
      background: palette.button.successHover,
    },
  },
});

export default injectSheet(styles)(Button);

Button.defaultProps = {
  label: "",
};

Button.propTypes = {
  classes: PropTypes.object,
  className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  type: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  rest: PropTypes.object,
};
