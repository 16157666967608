import React from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import { Icon } from "../Icon/Icon";
function ShellButton(props) {
  const {
    classes,
    icon,
    type,
    label,
    disabled,
    className,
    loading,
    onClick,
    ...rest
  } = props;

  const buttonTypes = {
    [classes.danger]: type === "danger",
    [classes.warning]: type === "warning",
    [classes.success]: type === "success",
  };
  return (
    <button
      disabled={disabled || loading}
      onClick={onClick}
      className={cs(
        classes.root,
        disabled ? classes.disabled : buttonTypes,
        type === "submit" && classes.warning,
        className
      )}
      type={type === "submit" ? type : undefined}
      {...rest}
    >
      {loading && (
        <span className={classes.loading}>
          <Icon icon="button-spinner" />
        </span>
      )}
      <span className={classes.label}>
        {icon && <Icon icon={icon} />}
        {label}
      </span>
    </button>
  );
}

const styles = ({ palette }) => ({
  root: {
    cursor: "pointer",
    color: palette.common.white,
    fontSize: "1rem",
    width: "100%",
    height: "50px",
    textAlign: "center",
    position: "relative",
  },

  danger: {
    backgroundColor: palette.button.danger,
    "&:hover": {
      backgroundColor: palette.button.dangerHover,
    },
  },
  warning: {
    backgroundColor: "#FBCE07",
    color: palette.secondaryTextColor,
    fontWeight: "500",
    "&:hover": {
      backgroundColor: "#ffd30f",
    },
  },
  disabled: {
    background: palette.button.disabled,
    "&:hover": {
      background: palette.button.disabled,
    },
  },
  label: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    "& > div": {
      marginRight: "5px",
    },
  },
  loading: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    "& + $label": {
      display: "none",
    },
  },
});
export default injectSheet(styles)(ShellButton);
