import React, { useCallback, useEffect, useRef, useState } from "react";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import Filters from "../../components/Filters/Filters";
import RoundButton from "../../components/shared/RoundButton/RoundButton";
import { useOutsideClickDetection } from "../../lib/hooks/useOutsideClickDetection";
import API from "../../api/API";
import TransactionsTable from "../../components/tables/TransactionsTable/TransactionsTable";
import MonthYearFilter from "../../components/Filters/MonthYearFilter/MonthYearFilter";
import { useMonth } from "../../lib/hooks/useMonth";
import { useProductTypes } from "../../lib/hooks/useProductTypes";

const date = new Date();

function Transactions(props) {
  const { classes } = props;
  const cardNumber = props.match.params?.id;

  const { t } = useTranslation();
  const filterRef = useRef(null);
  const [transactions, setTransactions] = useState([]);
  const [filterModalStatus, setFilterModalStatus] = useState("closed");
  const [downloadingTransactionType, setDownloadingTransactionType] = useState(
    ""
  );

  const [year, setYear] = useState(date.getFullYear());
  const [month, setMonth] = useState(date.getMonth() + 1);
  const monthObject = useMonth(month);
  const productTypes = useProductTypes();
  const [error, setError] = useState("");
  const [params, setParams] = useState({
    plate: null,
    page: 1,
    productId: null,
    startDate: null,
    endDate: null,
    cardNumber: cardNumber,
  });
  const [settings, setSettings] = useState({
    hasMore: false,
    loading: true,
  });

  useOutsideClickDetection(filterRef, () => setFilterModalStatus("closed"));

  const setLoadingMore = (loading) => {
    setSettings((prev) => ({
      ...prev,
      loading,
    }));
  };

  const getParams = () => {
    if (
      params.startDate ||
      params.endDate ||
      params.plate ||
      params.productId
    ) {
      return {
        plate: params.plate,
        productId: params.productId,
        startDate: params.startDate,
        endDate: params.endDate,
        pageNumber: params.page,
      };
    }
    return {
      cardNumber: params.cardNumber,
      pageNumber: params.page,
      month: month,
      year: year,
    };
  };

  const loadTransactions = useCallback(async () => {
    try {
      setLoadingMore(true);

      const params = getParams();

      const response = await API.transactions.get(params);

      setTransactions((prevTrans) => [
        ...prevTrans,
        ...response.data.transactions,
      ]);
      setSettings((prev) => ({ ...prev, hasMore: response.data.hasMore }));
    } catch (e) {
      // console.log(e);
      setError(e.toString());
    } finally {
      setLoadingMore(false);
    }
  }, [cardNumber, params, month, year]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    loadTransactions();
  }, [loadTransactions]);

  const handleMonthAndYearChange = () => {
    if (settings.isLoading) return;

    setTransactions([]);
    setParams((prev) => ({ ...prev, startDate: null, endDate: null, page: 1 }));
  };

  const handleParamsChange = (params) => {
    setTransactions([]);
    setParams((prev) => ({ ...prev, ...params }));
    setFilterModalStatus("closed");
  };

  const loadMore = () => {
    if (settings.loading) return;

    setParams((prev) => ({ ...prev, page: prev.page + 1 }));
  };

  const toggleFilterModal = () =>
    setFilterModalStatus((prevStatus) =>
      prevStatus === "open" ? "closed" : "open"
    );

  const getFileName = (params, month, type) => {
    const fileType = type === "excel" ? "xlsx" : "pdf";
    const fileName =
      params.startDate || params.endDate
        ? `${params.startDate}/${params.endDate}`
        : `${monthObject.label}-${year}`;

    return `${t("transactions")}-${fileName}.${fileType}`;
  };

  const downloadTransactions = async (type) => {
    setDownloadingTransactionType(type);
    const fileType =
      type === "excel"
        ? "vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        : "pdf";

    try {
      const params = getParams();
      const res = await API.transactions.downloadTransactions(type, params);
      const file = new Blob([res], { type: `application/${fileType}` });
      const fileName = getFileName(params, month, type);

      let a = document.createElement("a");
      let blobURL = URL.createObjectURL(file);
      a.download = fileName;
      a.href = blobURL;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);

      setDownloadingTransactionType("");
    } catch (e) {
      setDownloadingTransactionType("");
    }
  };

  const resolveFilterValue = (key, value) => {
    switch (key) {
      case "productId":
        return productTypes.find((productType) => productType.id === value)
          .label;
      default:
        return value;
    }
  };

  const getSelectedParams = (params) => {
    let selectedParams = {};

    const excludedParams = ["page", "cardId"];
    const lablesForKeys = {
      productId: t("productType"),
      month: t("month"),
      year: t("year"),
      plate: t("plage"),
      startDate: t("dateFrom"),
      endDate: t("dateTo"),
    };

    Object.keys(params).forEach((key) => {
      if (params[key] && excludedParams.indexOf(key) < 0) {
        selectedParams[key] = params[key];
      }
    });

    const monthAndYear = [];

    if (
      !selectedParams.startDate &&
      !selectedParams.endDate &&
      !selectedParams.productId &&
      !selectedParams.plate
    ) {
      monthAndYear.push({
        key: "month",
        label: lablesForKeys["month"],
        value: monthObject.label,
      });
      monthAndYear.push({
        key: "year",
        label: lablesForKeys["year"],
        value: year,
      });
    }

    selectedParams = Object.keys(selectedParams).map((key) => ({
      label: lablesForKeys[key] ?? t(key),
      key,
      value: resolveFilterValue(key, selectedParams[key]),
      onRemove() {
        setParams((prev) => ({ ...prev, [key]: null, page: 1 }));
        setTransactions([]);
      },
    }));

    return [...selectedParams, ...monthAndYear];
  };

  const tableActions = (
    <>
      <MonthYearFilter
        month={month}
        onMonthChange={setMonth}
        year={year}
        onYearChange={setYear}
        handleMonthAndYearChange={handleMonthAndYearChange}
      />

      <div className={classes.transactionActions} ref={filterRef}>
        <RoundButton
          icon="excel"
          label="Excel"
          type="success"
          disabled={transactions.length === 0}
          loading={downloadingTransactionType === "excel"}
          onClick={() => downloadTransactions("excel")}
        />
        <RoundButton
          disabled={transactions.length === 0}
          icon="pdf"
          label="PDF"
          type="danger"
          loading={downloadingTransactionType === "pdf"}
          onClick={() => downloadTransactions("pdf")}
        />
        <RoundButton
          icon="filter"
          label={t("filter")}
          iconClassName={classes.icon}
          onClick={toggleFilterModal}
        />
        <Filters
          status={filterModalStatus}
          onFiltersChange={handleParamsChange}
        />
      </div>
    </>
  );

  const data = transactions.map((transaction) => ({
    transaction_date: <span>{transaction.registrationDate.split(",")[0]}</span>,
    product_per_l: (
      <span>{`${transaction.product} / ${transaction.quantity} L`}</span>
    ),
    plate: <span>{transaction.plates}</span>,
    transaction_total: <span>{transaction.totalValue} €</span>,
  }));

  return (
    <>
      <TransactionsTable
        data={data}
        error={error}
        tableActions={tableActions}
        filterParams={getSelectedParams(params)}
        loading={settings.loading && params.page === 1}
      />
      {settings.hasMore && (
        <RoundButton
          disabled={settings.loading}
          className={classes.loadMoreButton}
          label={t("loadMore")}
          onClick={loadMore}
          loading={settings.loading && params.page > 1}
        />
      )}
    </>
  );
}

const styles = {
  transactionActions: {
    display: "flex",
    alignItems: "center",
  },
  icon: {
    width: "25px",
    height: "21px",
    "& g": {
      fill: "#434D71",
    },
  },
  loadMoreButton: {
    margin: "15px auto",
  },
  tableActions: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
};
export default injectSheet(styles)(Transactions);
