import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "react-jss";
import { BrowserRouter as Router } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";
import App from "./App";
import { AuthContextProvider } from "./lib/context/AuthContext/AuthContextProvider";
import { LanguageContextProvider } from "./lib/context/LanguageContext/LanguageContextProvider";
import { UIContextProvider } from "./lib/context/UIContext/UIContextProvider";
import { theme } from "./theme/theme";
import { ConfirmContextProvider } from "./lib/context/ConfirmContext/ConfirmContextProvider";

ReactDOM.render(
  <React.StrictMode>
    <Router>
      <ThemeProvider theme={theme}>
        <LanguageContextProvider>
          <AuthContextProvider>
            <UIContextProvider>
              <ConfirmContextProvider>
                <App />
              </ConfirmContextProvider>
            </UIContextProvider>
          </AuthContextProvider>
        </LanguageContextProvider>
      </ThemeProvider>
    </Router>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
