import React, { useCallback, useEffect, useState } from "react";
import injectSheet from "react-jss";
import InfoCard from "../../components/InfoCard/InfoCard";
import MyCardsTable from "../../components/tables/MyCardsTable/MyCardsTable";
import { useTranslation } from "react-i18next";
import API from "../../api/API";
import { displaySkeletonOrValue } from "../../lib/helpers/displaySkeletonOrValue";

const Home = (props) => {
  const { classes } = props;
  const { t } = useTranslation();
  const [visitsPerDay, setVisitsPerday] = useState(undefined);
  const [expensesPerMonth, setExpensesPerMonth] = useState({
    total: undefined,
    shop: undefined,
    derivate: undefined,
    iSaved: undefined,
  });

  function totalOfField(field, array) {
    return array.reduce((total, current) => total + current[field], 0);
  }

  const date = new Date();
  const year = date.getFullYear();
  const month = date.getMonth() + 1;

  useEffect(() => {
    API.user.getVisitsPerDay().then((data) => {
      data && setVisitsPerday(data.visitsPerDay);
    });
  }, []);

  const calculateTransactions = useCallback(async () => {
    try {
      let expenses = await API.user.getExpensesPerMonth(year);
      expenses = expenses.filter((expense) => expense.month === month); //Filter expenses by current month

      const totalDerivateValue = totalOfField(
        "totalValue",
        expenses.filter((transaction) => transaction.saleType === "Derivate")
      );
      const totalShopValue = totalOfField(
        "totalValue",
        expenses.filter((transaction) => transaction.saleType === "Market")
      );

      const totalAmount = totalDerivateValue + totalShopValue;

      const totalSaved = totalOfField("discountedTotalValue", expenses).toFixed(
        2
      );

      setExpensesPerMonth({
        total: totalAmount,
        shop: totalShopValue,
        derivate: totalDerivateValue,
        iSaved: totalSaved,
      });
    } catch (e) {}
  }, [month, year]);

  useEffect(() => {
    calculateTransactions();
  }, [calculateTransactions]);

  return (
    <>
      <div className={classes.root}>
        <InfoCard>
          <p className={classes.text}>
            {`${t("visitsPerMonth")} `}
            <span>{displaySkeletonOrValue(visitsPerDay, "45px")}</span>
            {` ${t("perDay")}`}
          </p>
        </InfoCard>
        <InfoCard>
          <p className={classes.text}>{t("expensesThisMonth")}</p>
          <h2>{displaySkeletonOrValue(expensesPerMonth.total)} €</h2>
          <p>
            Derivate:{" "}
            <b>{displaySkeletonOrValue(expensesPerMonth.derivate)} €</b>
          </p>
          <p>
            {t("shop")}:{" "}
            <b>{displaySkeletonOrValue(expensesPerMonth.shop)} €</b>
          </p>
        </InfoCard>
        <InfoCard>
          <p className={classes.text}>{t("iSaved")}</p>
          <h1 className={classes.expensesValue}>
            {displaySkeletonOrValue(expensesPerMonth.iSaved, "50%")} €
          </h1>
        </InfoCard>
      </div>
      <MyCardsTable />
    </>
  );
};
const styles = {
  root: {
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    marginBottom: "30px",
  },
  text: {
    margin: 0,
    fontSize: "1.1rem",
  },
  heading: {
    margin: 0,
  },
  expensesValue: {
    fontSize: "2.9rem",
  },
};
export default injectSheet(styles)(Home);
