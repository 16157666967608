import React, { useEffect, useState } from "react";
import injectSheet from "react-jss";
import cs from "classnames";
import Input from "../shared/Input/Input";
import RectButton from "../shared/RectButton/RectButton";
import { useTranslation } from "react-i18next";
import API from "../../api/API";

let timeout = undefined;

const ReqNewCardModal = (props) => {
  const { classes, status } = props;
  const { t } = useTranslation();
  const [vehiclePlate, setVehiclePlate] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const [success, setSuccess] = useState("");
  const [error, setError] = useState("");

  useEffect(() => {
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  const requestNewCard = async (plate) => {
    setSubmitting(true);
    setError("");
    setSuccess("");
    try {
      const response = await API.cards.requestNewCard(plate);
      setSuccess(response.message);
      setVehiclePlate("");
      setSubmitting(false);
      timeout = setTimeout(() => setSuccess(""), 5000);
    } catch (e) {
      setSubmitting(false);
      setError(e.response.data.errors.plate);
    }
  };

  return (
    <div
      className={cs(
        classes.root,
        status === "open" ? classes.show : classes.hide
      )}
    >
      <Input
        labelClassName={classes.input}
        label={t("writeVehicle")}
        id="writeVehicle"
        value={vehiclePlate}
        onChange={(e) => setVehiclePlate(e.target.value)}
      />
      {success && <p className={classes.success}>{success}</p>}
      {error && <p className={classes.error}>{error}</p>}
      <RectButton
        label={t("submit")}
        type="warning"
        onClick={() => requestNewCard(vehiclePlate)}
        loading={submitting}
      />
    </div>
  );
};
const styles = ({ palette, boxShadow, transitions }) => ({
  root: {
    position: "absolute",
    top: "90px",
    width: "300px",
    padding: "10px 15px",
    backgroundColor: palette.common.white,
    boxShadow: boxShadow.common,
    transition: transitions.faster,
    transformOrigin: "top",
    "@media(max-width:768px)": {
      left: 0,
      width: "100%",
    },
    "& > *": {
      marginBottom: "30px",
    },
    "& :last-child": {
      marginBottom: 0,
    },
  },
  show: { transform: "scale(1)" },
  hide: { transform: "scale(0)" },
  input: {
    fontSize: "0.99rem",
  },
  success: {
    color: palette.button.success,
    marginLeft: "10px",
  },
  error: {
    marginLeft: "10px",
    color: palette.button.danger,
  },
});
export default injectSheet(styles)(ReqNewCardModal);
