import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuthContext } from "../lib/context/AuthContext/AuthContextProvider";
import PropTypes from "prop-types";

export const PublicRoute = (props) => {
  const { component: Component, restricted, ...rest } = props;
  const { isAuthenticated } = useAuthContext();
  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthenticated && restricted ? (
          <Redirect to="/" />
        ) : (
          <Component {...props} />
        )
      }
    />
  );
};

PublicRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.func, PropTypes.object]),
  restricted: PropTypes.bool,
};
