import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../../shared/Icon/Icon";
import { useTranslation } from "react-i18next";

const NoCardsPlaceholder = (props) => {
  const { classes } = props;
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Icon className={classes.icon} icon="shell-notrans" />
      <h2 className={classes.text}>{t("noCardsYet")}</h2>
    </div>
  );
};

const styles = ({ palette }) => ({
  root: {
    height: "400px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  icon: {
    width: "150px",
    height: "150px",
  },
  text: {
    color: palette.button.danger,
  },
});
export default injectSheet(styles)(NoCardsPlaceholder);
