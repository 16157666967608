import React from "react";
import injectSheet from "react-jss";
import { Icon } from "../shared/Icon/Icon";
import { useLanguageContext } from "../../lib/context/LanguageContext/LanguageContext";
import { useTranslation } from "react-i18next";
const LanguageSwitcher = (props) => {
  const { classes } = props;
  const { i18n } = useTranslation();
  const { changeLanguage } = useLanguageContext();
  const lang = i18n.language;

  const toggleLanguage = () => {
    lang === "en" ? changeLanguage("sq") : changeLanguage("en");
  };
  return (
    <div className={classes.root} onClick={toggleLanguage}>
      <span>{i18n.language === "sq" ? "English" : "Shqip"}</span>
      <Icon icon="language" className={classes.icon} />
    </div>
  );
};

const styles = ({ palette }) => ({
  root: {
    display: "flex",
    color: palette.secondaryTextColor,
    alignItems: "center",
    cursor: "pointer",
    marginLeft: "auto",
    "&:hover": {
      color: palette.leadTextColor,
    },
  },
  icon: {
    marginLeft: "5px",
    width: "21px",
    height: "21px",
  },
});
export default injectSheet(styles)(LanguageSwitcher);
