import React from "react";
import cs from "classnames";
import { IconSvg } from "./Icon.generated";
import { createUseStyles } from "react-jss";
import PropTypes from "prop-types";

const useStyles = createUseStyles({
  Icon: {
    width: "25px",
    height: "25px",
    fill: "currentColor",
    display: "block",
    "&.range-slider-icon": {
      width: "28px",
      height: "28px",
    },
    "> svg": {
      display: "block",
      width: "100%",
    },
  },
});

export function Icon(props) {
  const classes = useStyles();
  if (!props.icon) {
    return null;
  }
  return (
    <div
      style={props.style}
      className={cs(classes.Icon, props.className)}
      onClick={props.onClick}
    >
      {IconSvg[props.icon]}
    </div>
  );
}

Icon.propTypes = {
  icon: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
};
