export const theme = {
  palette: {
    leadColor: "#F3F5F6",
    leadTextColor: "#7B8C92",
    sidebarColor: "#F6F8F8",
    secondaryTextColor: "#434D71",
    activeItem: "#F8FAFB",
    borderColor: "#DEDEDE",
    error: "#FC405B",
    tableRowHover: "#eff3f6",

    button: {
      normal: "#DEDEDE",
      normalHover: "#e6e6e6",
      danger: "#DD1D21",
      dangerHover: "#ec2226",
      warning: "#FBCE07",
      warningHover: "#fcd31d",
      success: "#1FB56F",
      successHover: "#21c478",
      disabled: "#7B8C92",
    },

    common: {
      white: "#fff",
      black: "#000",
    },
  },

  transitions: {
    common: "all 0.3s cubic-bezier(0.3, 0, 0.2, 1)",
    faster: "all 0.15s cubic-bezier(0.3, 0, 0.2, 1)",
  },

  boxShadow: {
    common: "2px 8px 19px 0px rgba(180,184,188,0.16)",
  },

  typography: {
    headingFontSize: 19,
    defaultFontSize: 18,
    font: "Shell",
    weight: {
      light: 300,
      normal: 400,
      medium: 500,
      bold: 600,
      bolder: 700,
    },
  },
  size: {
    spacing: 40,

    header: {
      height: 103,
      iconSize: 30,
    },
    sidebar: {
      width: 350,
    },
  },
  hidden: {
    display: "none",
  },
  disabled: {
    opacity: 0.5,
  },
  noSelect: {
    "-webkit-tap-highlight-color": "transparent",
    "-webkit-touch-callout": "none",
    "-webkit-user-select": " none",
    "-khtml-user-select": "none",
    "-moz-user-select": "none",
    "-ms-user-select": "none",
    userSelect: "none",
  },
};
