import React from "react";
import injectSheet from "react-jss";
import { useTranslation } from "react-i18next";
import cs from "classnames";
import SideBarItem from "./SideBarItem";
import SideBarHeader from "./SideBarHeader";
import { useUIContext } from "../../lib/context/UIContext/UIContext";

const styles = ({ palette, transitions }) => ({
  root: {
    background: palette.sidebarColor,
    position: "fixed",
    height: "100%",
    width: "350px",
    borderRight: "1px solid" + palette.borderColor,
    transition: transitions.common,
    "& > a": {
      textDecoration: "none",
    },
  },

  hide: {
    transform: "translateX(-350px)",
  },
  show: {
    transform: "translateX(0)",
  },
  "@media (max-width:768px)": {
    root: {
      zIndex: "10",
      transform: "translateX(-350px)",
    },
    show: {
      transform: "translateX(0)",
    },
  },
});

const SideBar = (props) => {
  const { classes } = props;
  const { sidebarOpen } = useUIContext();
  const { t } = useTranslation();

  const sidebarItems = [
    { icon: "transactions", name: t("transactions"), path: "/transactions" },
    { icon: "card", name: t("myCards"), path: "/my-cards" },
    { icon: "profile", name: t("myProfile"), path: "/my-profile" },
  ];

  return (
    <div
      className={cs(classes.root, sidebarOpen ? classes.show : classes.hide)}
    >
      <SideBarHeader />
      {sidebarItems.map((item, idx) => (
        <SideBarItem
          key={idx}
          icon={item.icon}
          name={item.name}
          path={item.path}
        />
      ))}
    </div>
  );
};

export default injectSheet(styles)(SideBar);
